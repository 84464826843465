import { HttpMethod, ServiceType } from '../models/Request';
import { SUCCESS_RESPONSE } from '@constants/StatusCode';
import { urls } from '../api/urls';
import { sendMessage } from '../api/contactUs';

export const getPrefixNumber = async (): Promise<any> => {
    try {
        const request = {
            resource: urls.notification.prefixNumber,
            method: HttpMethod.GET,
            service: ServiceType.NOTIFICATION,
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { data, statusCode }:any = await sendMessage(request);
        if (SUCCESS_RESPONSE.includes(statusCode)) {            
            return data;
        }

        return [];
    } catch (error) {
        return error;
    }
};
