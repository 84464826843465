export { default } from "./HeaderNav";
const blogUrl = process.env.REACT_APP_BLOG_ONE_PAGE;

/**
 * This data navbar
 */
export const dataNavbarList = [
  { text: "Nuestras soluciones", isIcon: false, nameElement: "our-solution", offSetLink:-30},
  { text: "Servicios", isIcon: false, nameElement: "services", offSetLink:-30 },
  { text: "Sobre nosotros", isIcon: false, nameElement: "about-us", offSetLink:-30 },
  {
    text: "Blog",
    isIcon: true,
    listLink: [
      { name: "CCXC le recomienda", href: `${blogUrl}/categories/ccxc-le-recomienda` },
      { name: "Videos", href:`${blogUrl}/categories/tips`},
      { name: "Archivo de noticias", href:`${blogUrl}/categories/archivo-de-noticias`},
    ],
    nameElement:''
  },
  { text: "Contáctenos", isIcon: false, nameElement: "contact-us", offSetLink:-80 },
];
