import React, { useEffect, useState } from 'react';
import Button from '@components/button';
import { CheckboxMain } from '@components/checkbox';
import {
    BorderColors,
    DEFAULT_REQUIRED_TEXT,
    INVALID_EMAIL,
    INVALID_PHONE_NUMBER,
    IOptionSelect,
    SelectInput,
    TextArea,
    TextInput,
} from '@components/input';
import { ICountryOption, IForm, MAX_LENGTH_PHONE, TYPE_INPUT_FORM, optionSelectForm } from '.';
import { validateEmail } from '@utils/Validation';
import { getPrefixNumber } from '@services/contactForm';
import { VALIDATE_NUMBER_ZERO } from '..';

const Form: React.FC<IForm> = ({
    handleChange = (): void => {},
    handlePasteLetters = (): void => {},
    dataForm,
    handleChangeSelect = (): void => {},
    onClickSave = (): void => {},
    activeValidate = false,
    checkDataProcessing = false,
    handleDataProcessing = (): void => {},
}) => {
    const [optionsPrefixNumber, setOptionsPrefixNumber] = useState<IOptionSelect[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getPrefix = async (): Promise<any> => {
        const prefixData = await getPrefixNumber();
        const prefix = prefixData?.map((option: ICountryOption) => ({
            id: option.id,
            key: option.country,
            value: option.countryCode,
        }));
        setOptionsPrefixNumber(prefix);
    };
    useEffect(() => {
        getPrefix();
    }, []);

    return (
        <div className="class-container-form">
            <h3 className="class-container-form__title">Escríbanos sus dudas o inquietudes</h3>
            <p className="class-container-form__text--responsive">
                Si le interesa saber más sobre nuestros productos o necesita una asesoría, agende una cita con nosotros:
            </p>
            <div className="class-container-form__content--inputs">
                <TextInput
                    name="name_surname"
                    value={dataForm.name_surname}
                    placeholder="Nombre y apellido"
                    classWrapper="mt-2.25 lg:w-71.25 xlg:w-full w-full"
                    borderColor={BorderColors.GREEN}
                    onChange={(e): void => handleChange(e)}
                    onPaste={handlePasteLetters}
                    colorText="gray-contact"
                    type="text"
                    alphabetical
                    required={activeValidate && !dataForm.name_surname}
                />
                <TextInput
                    name="email"
                    value={dataForm.email}
                    placeholder="Correo electrónico"
                    classWrapper="mt-2.25 lg:w-71.25 w-full xlg:w-full"
                    borderColor={BorderColors.GREEN}
                    onChange={(e): void => handleChange(e)}
                    colorText="gray-contact"
                    required={!!((activeValidate && !dataForm.email) || (dataForm.email && !validateEmail(dataForm.email)))}
                    requiredText={!dataForm.email ? DEFAULT_REQUIRED_TEXT : INVALID_EMAIL}
                />
                <div className="flex mt-2.25 lg:w-71.25 w-full xlg:w-full">
                    <SelectInput
                        name="prefix_number"
                        value={dataForm.prefix_number}
                        borderColor={BorderColors.GREEN}
                        classWrapper="mr-2 border rounded w-16.5 form-text-inputs"
                        classesInput="overflow-y-scroll overflow-x-hidden max-h-90 container-modal scroll-form-contact"
                        options={optionsPrefixNumber}
                        optionSelected={(e: IOptionSelect): void => handleChangeSelect(TYPE_INPUT_FORM.PREFIX, e)}
                    />
                    <TextInput
                        name="phone"
                        value={dataForm.phone}
                        placeholder="Teléfono de contacto"
                        classWrapper="w-53.15"
                        borderColor={BorderColors.GREEN}
                        onChange={(e): void => handleChange(e)}
                        colorText="gray-contact"
                        required={
                            !!(
                                (activeValidate && !dataForm.phone) ||
                                (dataForm.phone && dataForm.phone.startsWith(VALIDATE_NUMBER_ZERO))
                            )
                        }
                        requiredText={!dataForm.phone ? DEFAULT_REQUIRED_TEXT : INVALID_PHONE_NUMBER}
                        maxLength={MAX_LENGTH_PHONE}
                        numeric
                    />
                </div>
                <TextInput
                    name="company_name"
                    value={dataForm.company_name}
                    placeholder="Nombre de la empresa"
                    classWrapper="mt-2.25 lg:w-71.25 w-full xlg:w-full"
                    borderColor={BorderColors.GREEN}
                    onChange={(e): void => handleChange(e)}
                    colorText="gray-contact"
                    required={activeValidate && !dataForm.company_name}
                />
                <SelectInput
                    name="affair"
                    value={dataForm.affair}
                    placeholder="Asunto"
                    classWrapper="w-full mt-2.25 form-text-inputs"
                    borderColor={BorderColors.GREEN}
                    options={optionSelectForm}
                    optionSelected={(e: IOptionSelect): void => handleChangeSelect(TYPE_INPUT_FORM.AFFAIR, e)}
                    required={activeValidate && !dataForm.affair}
                    classesInput="overflow-y-scroll max-h-90 container-modal scroll-form-contact"
                />
                <TextArea
                    name="description"
                    value={dataForm.description}
                    placeholder="Escriba sus dudas o inquietudes…"
                    classesWrapper="mt-2.25 w-full text-gray-contact"
                    borderColor={BorderColors.GREEN}
                    classesInput="min-h-42.5 pt-3.5"
                    onChange={(e): void => handleChange(e)}
                    required={activeValidate && !dataForm.description}
                />
                <CheckboxMain
                    checked={checkDataProcessing}
                    onChange={handleDataProcessing}
                    label="Si doy autorización expresa para el tratamiento de los datos aquí consignados"
                    classCheck="mt-[0.8rem]"
                    classBox="mt-[1.09375rem] mb-[1.84375rem] mr-auto "
                    classLabel="text-base text-left font-poppins text-gray-dark"
                    validate={activeValidate && !checkDataProcessing}
                />
            </div>
            <Button
                text="Enviar"
                classes="bg-green w-40 rounded font-poppins-bold"
                onClick={(): void => {
                    onClickSave();
                }}
            />
        </div>
    );
};

export default Form;
