import { HttpMethod, ServiceType } from '../models/Request';
import { urls } from '../api/urls';
import { sendMessage } from '../api/contactUs';

export const aboutUs = async (data: any): Promise<any> => {
    try {
        const request = {
            resource: urls.notification.contactUs,
            method: HttpMethod.POST,
            service: ServiceType.NOTIFICATION,
            data,
        };
        const { statusCode }: any = await sendMessage(request);
        return statusCode;
    } catch (error) {
        return error;
    }
};
