import { IGenericRecord } from '@models/GenericRecord';
import { AxiosRequestConfig, Method, AxiosRequestHeaders } from 'axios';
import axios from 'axios';

class AxiosClient {
    baseUrl = `${process.env.REACT_APP_BASE_URL}/api`;

    formatUrl(uri: string): string {
        return `${this.baseUrl}/${uri}`;
    }

    sendForm(
        method: Method,
        uri: string,
        data: unknown,
        isFile?: boolean,
        contentType: AxiosRequestHeaders = { 'Content-Type': 'application/json' }
    ): Promise<unknown> {
        const url = uri.includes(this.baseUrl) ? uri : this.formatUrl(uri);

        let request: AxiosRequestConfig = {
            method,
            url,
            data,
            headers: contentType,
        };

        if (isFile) {
            request = {
                ...request,
                responseType: 'arraybuffer',
            };
        }

        return axios(request).then(res => res.data).catch(error => {
            
            return error;
          });
    }
    post(uri: string, data: unknown, isFile = false, contentType: IGenericRecord = { 'Content-Type': 'application/json' }): Promise<unknown> {
        return this.sendForm('POST', uri, data, isFile, contentType);
    }
}

export default new AxiosClient();